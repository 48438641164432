import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import vSelect from 'vue-select'
import TextHighlight from 'vue-text-highlight'
import VueI18n from 'vue-i18n'
import messages from './lang'
import VTooltip from 'v-tooltip'
import VueGtag from 'vue-gtag'
import ScrollLoader from 'vue-scroll-loader'
import VueMeta from 'vue-meta'
import { directive as onClickaway } from 'vue-clickaway'
// import { ANALYTICS_KEY } from './env'
import { ANALYTICS_KEY, SENTRY_API } from './env'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
if (SENTRY_API) {
  // make sure sentry doesn't run locally
  Sentry.init({
    dsn: SENTRY_API,
    integrations: [new VueIntegration({ Vue, attachProps: true })]
  })
}

Vue.directive('on-clickaway', onClickaway)
Vue.use(ScrollLoader)
Vue.use(BootstrapVue)
Vue.use(VueGtag, {
  config: { id: ANALYTICS_KEY }
})

Vue.component('v-select', vSelect)

Vue.component('text-highlight', TextHighlight)

var VueCookie = require('vue-cookie')

Vue.use(VueCookie)
Vue.use(VueI18n)
Vue.use(VTooltip)
Vue.use(VueMeta)
Vue.directive('tooltip', VTooltip)
export const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'en',
  messages
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
