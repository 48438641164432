import de from './translations/de.json'
import en from './translations/en.json'
import { LANGUAGE } from '../env'

let lang = { de }
// console.log(LANGUAGE)
// console.log(typeof LANGUAGE)
if (LANGUAGE === 'en') {
  lang = { en }
}

export default lang
