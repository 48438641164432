<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
  @import "assets/css/bootstrap.css";
  /*
  imported via CDN in index.html
  @import "assets/css/bootstrap-vue.css";*/
  @import "assets/fontawesome/all.css";
  @import "assets/css/vs-search-custom.css";
  @import "assets/css/style.css";
  @import "~vue-select/dist/vue-select.css";
</style>
<script>
export default {
  name: 'App',
  metaInfo () {
    return {
      title: 'gender app · Geschlechtergerechte Sprache',
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content: 'Gendern mit demokratischem Wörterbuch und Office Add-ins 🤹'
        },
        { name: 'robots', vmid: 'robots', content: 'index,follow' }
      ]
    }
  },
  data () {
    return {
      logo: 'https://genderapp.org/img/icons/favicon-80x80.png'
    }
  }
}
</script>
