import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: { title: 'gender app · Geschlechtergerechte Sprache' }
  },
  {
    path: '/dict/:specific',
    name: 'specific',
    component: () => import('../views/Home.vue'),
    meta: { title: 'gender app · Geschlechtergerechte Sprache' }
  },
  {
    path: '/home',
    name: 'homepage',
    component: () => import('../views/Home.vue'),
    meta: { title: 'gender app · Geschlechtergerechte Sprache' }
  },
  {
    path: '/login',
    name: 'auth.login',
    component: () => import('../views/auth/Login.vue'),
    meta: { title: 'Anmelden · gender app' }
  },
  {
    path: '/register',
    name: 'auth.register',
    component: () => import('../views/auth/Register.vue'),
    meta: { title: 'Registrierung · gender app' },
    metaTags: [
      {
        name: 'description',
        vmid: 'description',
        content: 'Werde gender app Mitglied! 🎈'
      },
      {
        property: 'og:description',
        content: 'Werde gender app Mitglied! 🎈'
      }
    ]
  },
  {
    path: '/password-reset',
    name: 'auth.password_reset_email',
    component: () => import('../views/auth/ForgotPassword'),
    meta: { title: 'Password Reset · gender app' }
  },
  {
    path: '/profile/:username',
    name: 'auth.profile',
    component: () => import('../views/auth/Profile.vue'),
    meta: { title: 'Profil · gender app' }
  },
  {
    path: '/password/reset/:uid/:token',
    name: 'auth.password_reset',
    component: () => import('../views/auth/ResetPassword'),
    meta: { title: 'wb · gender app' }
  },
  {
    path: '/dictionary_entry/add',
    name: 'dictionary.entry.add',
    component: () => import('../views/dictionary/entry/Add.vue'),
    meta: { title: 'Neuer Eintrag · gender app' }
  },
  {
    path: '/dictionary_entry/edit/:id',
    name: 'dictionary.entry.edit',
    component: () => import('../views/dictionary/entry/Edit.vue'),
    meta: { title: 'Bearbeiten · gender app' }
  },
  {
    path: '/translate/',
    name: 'lektorat.solution',
    component: () => import('../views/lektorat/Solution'),
    meta: { title: 'gender app translate · gender app' }
  },
  {
    path: '/website-check/',
    name: 'lektorat.website-check',
    component: () => import('../views/lektorat/WebsiteCheck'),
    meta: { title: 'Website-Check · gender app' }
  },
  {
    path: '/language/',
    name: 'language',
    component: () => import('../views/Language'),
    // eslint-disable-next-line no-undef
    meta: { title: 'Geschlechtergerechte Sprache · gender app' }
  },
  {
    path: '/support/',
    name: 'support',
    component: () => import('../views/Support'),
    meta: { title: 'Unterstützung · gender app' }
  },
  {
    path: '/about/',
    name: 'about',
    component: () => import('../views/About'),
    meta: { title: 'Über gender app · gender app' }
  },
  {
    path: '/team/',
    name: 'team',
    component: () => import('../views/Team'),
    meta: { title: 'Team · gender app' }
  },
  {
    path: '/inprint/',
    name: 'inprint',
    component: () => import('../views/Inprint'),
    meta: { title: 'Impressum · gender app' }
  },
  {
    path: '/tos/',
    name: 'tos',
    component: () => import('../views/TOS'),
    meta: { title: 'AGBs & Datenschutzerklärung · gender app' }
  },
  {
    path: '/add-in-privacy-policy/',
    name: 'add-in-privacy-policy',
    component: () => import('../views/addInPrivacyPolicy'),
    meta: { title: 'Add-in Datenschutzerklärung · gender app' }
  },
  {
    path: '/manual/',
    name: 'manual',
    component: () => import('../views/Manual'),
    meta: { title: 'Handbuch · gender app' }
  },
  {
    path: '/commercial/',
    name: 'commercial',
    component: () => import('../views/Commercial'),
    meta: { title: 'Kommerzielle Nutzung · gender app' }
  },
  {
    path: '/add-in/',
    name: 'add-in',
    component: () => import('../views/Addin'),
    meta: { title: 'Office Add-ins · gender app' }
  },
  {
    path: '/team-dashboard',
    name: 'team.team-dashboard',
    component: () => import('../views/team/TeamDashboard'),
    meta: { title: 'Team Dashboard · gender app' }
  },
  {
    path: '/create-team/',
    name: 'team.create-team',
    component: () => import('../views/team/CreateTeam'),
    meta: { title: 'Erstelle ein Team · gender app' }
  },
  {
    path: '/add-team-member/',
    name: 'team.add-team-member',
    component: () => import('../views/team/AddTeamMember'),
    meta: { title: 'Füge eine neue Person deinem Team zu · gender app' }
  },
  {
    path: '/404/',
    component: () => import('../views/error/Error404.vue')
  },
  {
    path: '*',
    component: () => import('../views/error/Error404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: '',
  linkExactActiveClass: '',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})
export default router
